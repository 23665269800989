var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = void 0;
  var _default = (classes, suffix) => classes ? classes.split(" ").map(c => `${c}-${suffix}`).join(" ") : "";
  exports.default = _default;
  exports = exports.default;
  return exports;
}