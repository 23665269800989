import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;
import * as _isFunction3 from "lodash/isFunction";
var _isFunction = "default" in _isFunction3 ? _isFunction3.default : _isFunction3;
import * as _isNil3 from "lodash/isNil";
var _isNil = "default" in _isNil3 ? _isNil3.default : _isNil3;
import * as _negate3 from "lodash/negate";
var _negate = "default" in _negate3 ? _negate3.default : _negate3;
import * as _reduce3 from "lodash/reduce";
var _reduce = "default" in _reduce3 ? _reduce3.default : _reduce3;
import * as _map3 from "lodash/map";
var _map = "default" in _map3 ? _map3.default : _map3;
import { dew as _preview2Dew } from "./preview";
import { dew as _fonts2Dew } from "./fonts";
import { dew as _mediaQueries2Dew } from "./mediaQueries";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2.default;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = skeleton;
  var _isFunction2 = _interopRequireDefault(_isFunction);
  var _isNil2 = _interopRequireDefault(_isNil);
  var _negate2 = _interopRequireDefault(_negate);
  var _reduce2 = _interopRequireDefault(_reduce);
  var _map2 = _interopRequireDefault(_map);
  var _preview = _interopRequireDefault(_preview2Dew());
  var _fonts = _fonts2Dew();
  var _mediaQueries = _interopRequireDefault(_mediaQueries2Dew());
  function skeleton(options) {
    const {
      backgroundColor = "",
      beforeDoctype = "",
      breakpoint = "480px",
      content = "",
      fonts = {},
      mediaQueries = {},
      headStyle = [],
      componentsHeadStyle = {},
      headRaw = [],
      preview,
      title = "",
      style = [],
      forceOWADesktop,
      inlineStyle,
      lang,
      dir
    } = options;
    const langAttribute = lang ? `lang="${lang}" ` : "";
    const dirAttribute = dir ? `dir="${dir}" ` : "";
    return `${beforeDoctype ? `${beforeDoctype}\n` : ""}<!doctype html>
<html ${langAttribute}${dirAttribute}xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
  <head>
    <title>${title}</title>
    <!--[if !mso]><!-->
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <!--<![endif]-->
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <style type="text/css">
      #outlook a { padding:0; }
      body { margin:0;padding:0;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%; }
      table, td { border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt; }
      img { border:0;height:auto;line-height:100%; outline:none;text-decoration:none;-ms-interpolation-mode:bicubic; }
      p { display:block;margin:13px 0; }
    </style>
    <!--[if mso]>
    <noscript>
    <xml>
    <o:OfficeDocumentSettings>
      <o:AllowPNG/>
      <o:PixelsPerInch>96</o:PixelsPerInch>
    </o:OfficeDocumentSettings>
    </xml>
    </noscript>
    <![endif]-->
    <!--[if lte mso 11]>
    <style type="text/css">
      .mj-outlook-group-fix { width:100% !important; }
    </style>
    <![endif]-->
    ${(0, _fonts.buildFontsTags)(content, inlineStyle, fonts)}
    ${(0, _mediaQueries.default)(breakpoint, mediaQueries, forceOWADesktop)}
    <style type="text/css">
    ${(0, _reduce2.default)(componentsHeadStyle, (result, compHeadStyle) => `${result}\n${compHeadStyle(breakpoint)}`, "")}
    ${(0, _reduce2.default)(headStyle, (result, headStyle) => `${result}\n${headStyle(breakpoint)}`, "")}
    </style>
    <style type="text/css">
    ${(0, _map2.default)(style, s => (0, _isFunction2.default)(s) ? s(breakpoint) : s).join("")}
    </style>
    ${headRaw.filter((0, _negate2.default)(_isNil2.default)).join("\n")}
  </head>
  <body style="word-spacing:normal;${backgroundColor ? `background-color:${backgroundColor};` : ""}">
    ${(0, _preview.default)(preview)}
    ${content}
  </body>
</html>
  `;
  }
  exports = exports.default;
  return exports;
}