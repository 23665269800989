import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;
import * as _isEmpty3 from "lodash/isEmpty";
var _isEmpty = "default" in _isEmpty3 ? _isEmpty3.default : _isEmpty3;
import * as _map3 from "lodash/map";
var _map = "default" in _map3 ? _map3.default : _map3;
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2.default;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = buildMediaQueriesTags;
  var _isEmpty2 = _interopRequireDefault(_isEmpty);
  var _map2 = _interopRequireDefault(_map);

  // eslint-disable-next-line import/prefer-default-export
  function buildMediaQueriesTags(breakpoint, mediaQueries = {}, forceOWADesktop = false) {
    if ((0, _isEmpty2.default)(mediaQueries)) {
      return "";
    }
    const baseMediaQueries = (0, _map2.default)(mediaQueries, (mediaQuery, className) => `.${className} ${mediaQuery}`);
    const thunderbirdMediaQueries = (0, _map2.default)(mediaQueries, (mediaQuery, className) => `.moz-text-html .${className} ${mediaQuery}`);
    const owaQueries = (0, _map2.default)(baseMediaQueries, mq => `[owa] ${mq}`);
    return `
    <style type="text/css">
      @media only screen and (min-width:${breakpoint}) {
        ${baseMediaQueries.join("\n")}
      }
    </style>
    <style media="screen and (min-width:${breakpoint})">
      ${thunderbirdMediaQueries.join("\n")}
    </style>
    ${forceOWADesktop ? `<style type="text/css">\n${owaQueries.join("\n")}\n</style>` : ``}
  `;
  }
  exports = exports.default;
  return exports;
}