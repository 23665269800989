var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = void 0;

  // # OPTIMIZE ME: — check if previous conditionnal is `<!--[if mso | I`]>` too
  var _default = content => content.replace(/(<!\[endif]-->\s*?<!--\[if mso \| IE]>)/gm, "");
  exports.default = _default;
  exports = exports.default;
  return exports;
}